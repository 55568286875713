<style scoped>
.btn {
    width: 100%;
    margin-bottom: 10px !important;
}
</style>

<template>

    <div>
    
        <h2>{{$t('labels.chooseLanguage')}}</h2>
        <div class="mt-3">
            <v-btn class="btn" @click="changeTo('nl')" color='primary'>Nederlands</v-btn>
            <v-btn class="btn" @click="changeTo('en')" color='primary'>English</v-btn>
            <v-btn class="btn" @click="changeTo('fr')" color='primary'>Français</v-btn>
        </div>

    </div>


</template>

<script>

export default {
    methods: {
        async changeTo (lang) {
            if (this.$store.getters.userProfile && window.localStorage.getItem('token')) {
                // Logged in user
                const token = await this.$api.changeLanguage(lang)
                window.localStorage.setItem('token', token)
                window.location.reload()
            } else {
                // landing page and not logged in
                window.setLocale(lang)
            }
            this.$emit('ok')
            
        }
    }
}
</script>
