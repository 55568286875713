<template>
    
    <v-btn
      class="ml-2"
      min-width="0"
      text
      @click="changeLang"
    >
      <v-icon>mdi-web</v-icon>
    </v-btn>

</template>

<script>
import ChooseAndSetLanguage from './ChooseAndSetLanguage.vue'
export default {
    methods: {
        changeLang() {
        this.$openModal(ChooseAndSetLanguage, null, this)
          .then(() => {
            
          })
      },
    }
}
</script>
