<style scoped>

.bonsai-logo {
  padding:25px; 
  width: 150px;
}

.left {
  /* INSIDE THIS PANEL WE FILL VERTICALLY */
  display:flex;
  flex-direction: column; 
  align-items: center;
  justify-content: space-between;

  height: 100%;

  flex: 1 0 50%; /* RELATES TO THE PARENT */
}

.right {
  height: 100%;
  display:flex;
  flex: 1 0 50%;
  align-items: center;
  justify-content: center;
  background: #1798FF;
}
</style>
<template>

<div style="width:100%; height: 100%; display: flex; flex-direction: row">

   
  <div style="position: absolute; top:25px; right:25px;">
    <change-lang-btn />
    <a style="font-weight: bold; color: #142b3c; text-decoration: none;" :href="contactUrl">{{$t('labels.contactus')}}</a>
  </div>



  <div class="left">
    <div style="align-self: start">
      <img class="bonsai-logo" v-cdn-src="'Bonsai_Logo_Green.svg'" />
    </div>

    <div style="width: 80%; margin-top: auto; margin-bottom: auto">
      <router-view/>
    </div>
  </div>

  <div class="right d-none d-md-flex" :style="{'background-size': 'cover', 'background-image': 'url(' + require('@/assets/landing.png') + ')'}" >
  

  <div style="position: absolute; top:25px; right:25px;">
    <change-lang-btn style="color:white" />
    <a style="font-weight: bold; color: white; text-decoration: none;" :href="contactUrl">{{$t('labels.contactus')}}</a>
  </div>
  
  </div>
  

</div>

</template>

<script>
import ChangeLangBtn from '@/components/ChangeLangBtn'
export default {
  components: {
    'change-lang-btn' : ChangeLangBtn
  },
  data () {
    return {
      contactUrl: "https://www.paybonsai.com/contact"
    }
  }
}
</script>